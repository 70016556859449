import Domain from 'lib/Config'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'

export const GET_CONFIG = '[ADMIN] GET_CONFIG'
export const GET_API_KEY = '[ADMIN] GET_API_KEY '
export const CONFIG_STATE_LOADING = '[ADMIN] CONFIG_STATE_LOADING'
export const CONFIG_GENERATE_LOADING = '[ADMIN] CONFIG_GENERATE_LOADING'
export const CONFIG_LOADING = '[ADMIN] CONFIG_LOADING'
export const CONFIG_ERROR = '[ADMIN] CONFIG_ERROR'
export const CONFIG_GENERATE_ERROR = '[ADMIN] CONFIG_GENERATE_ERROR'
export const CONFIG_NULL = '[ADMIN] CONFIG_NULL'

export function getConfig () {
  const request = axios.get(`${Domain}/crm/api/system/config`)
  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_CONFIG,
          payload: response.data.data
        })
      })
      .catch(error => {
        return dispatch({
          type: CONFIG_ERROR,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : 'Error! Cannot get configuration'
        })
      })
}

export function generateApiToken () {
  const request = axios.post(`${Domain}/crm/api/system/api-token`)
  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_API_KEY,
          payload: response.data.data.apiToken
        })
      })
      .catch(error => {
        NotificationManager.error('Unable to generate api key')
        return dispatch({
          type: CONFIG_GENERATE_ERROR,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : 'Error! Cannot get api key'
        })
      })
}
export function updateConfig (data, navigate) {
  const request = axios.post(`${Domain}/crm/api/system/config`, data)
  return dispatch =>
    request
      .then(resp => {
        NotificationManager.success('Configuration updated successfully')
        dispatch(configLoading(true))
        dispatch(configStateLoading(false))
        dispatch(getConfig())
        navigate('/admin/generalConfiguration')
      })
      .catch(error => {
        dispatch(configStateLoading(false))
        error.response
          ? NotificationManager.error(error.response.data.message)
          : NotificationManager.error(
              'Error! Cannot update chat flow configuration'
            )
      })
}

export function setConfigNull () {
  return dispatch => {
    return dispatch({
      type: CONFIG_NULL
    })
  }
}

export function configStateLoading (val) {
  return dispatch => {
    return dispatch({
      type: CONFIG_STATE_LOADING,
      payload: val
    })
  }
}

export function configGenerateLoading (val) {
  return dispatch => {
    return dispatch({
      type: CONFIG_GENERATE_LOADING,
      payload: val
    })
  }
}

export function configLoading (val) {
  return dispatch => {
    return dispatch({
      type: CONFIG_LOADING,
      payload: val
    })
  }
}
