/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react'
import Loadable from 'react-loadable'

// rct page loader
import RctPageLoader from 'components/RctPageLoader/RctPageLoader'
// import PreloadTable from "components/PreloadLayout/PreloadTable";
// import PreloadProduct from "components/PreloadLayout/preloadProduct";

// ecommerce dashboard
const AsyncFlowsComponent = Loadable({
  loader: () => import('routes/flows'),
  loading: () => <RctPageLoader />
})

export { AsyncFlowsComponent }
