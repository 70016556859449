import React from 'react'
// async component
import { AsyncFlowsComponent } from 'components/AsyncComponent/AsyncComponent'

import Flow from 'routes/flow'
import GeneralConfiguration from 'routes/generalConfiguration'

export default [
  {
    path: 'flows',
    component: <AsyncFlowsComponent />
  },
  {
    path: 'createFlow',
    component: <Flow />
  },
  {
    path: 'updateFlow/:id',
    component: <Flow />
  },
  {
    path: 'viewFlow/:id',
    component: <Flow />
  },
  {
    path: 'generalConfiguration',
    component: <GeneralConfiguration />
  }
]
