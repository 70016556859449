// nav links
const navlinks = [
  {
    id: 1,
    menu_title: "Chat Flows",
    menu_icon: "zmdi zmdi-view-dashboard",
    path: "/admin/flows",
    child_routes: null,
  },
  {
    id: 2,
    menu_title: "General Configuration",
    menu_icon: "zmdi zmdi-settings",
    path: "/admin/generalConfiguration",
    child_routes: null,
  },
];

export default navlinks;
