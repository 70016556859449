import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import Settings from "./settings";
import FlowBuilder from "./flowBuilder";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import ConfirmDialogue from "components/Dialogue/ConfirmDialogue";
import { Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import usePrompt from "./usePrompt";

function Index(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const tabs = ["Configuration", "Builder"];

  const { id } = useParams();

  const [pageType, setPageType] = useState("Add");
  const [tabValue, setTabValue] = useState(0);
  // Used to prevent allowing going to next page unless settings form is filled
  const [allowNext, setAllowNext] = useState(false);
  const [settingsData, setSettingsData] = useState({});
  const [agents, setAgents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [historyDialogue, setHistoryDialogue] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  // Prompt for route change check
  // usePrompt('If you proceed your current progress will be deleted?', showPrompt)
  const generalConfigReducer = useSelector(
    ({ generalConfig }) => generalConfig
  );
  const chatFlowReducer = useSelector(({ chatFlow }) => chatFlow);

  let [questions, setQuestions] = useState([
    {
      type: "MCQ",
      uuid: uuidv4(),
      statement: "",
      displayOrder: 1,
      options: [
        {
          displayOrder: 1,
          statement: "",
          linkType: "Link-To-Question",
          linkId: "",
          responseText: "",
          uuid: uuidv4(),
          fileLink: "",
        },
      ],
    },
  ]);

  // In case of new chatflow, load previous unsaved chatflow if exists
  useEffect(() => {
    if (location.pathname.includes("create")) {
      let result = JSON.parse(localStorage.getItem("chatFlowHistory"));
      if (result) {
        setHistoryDialogue(true);
      }
    }
  }, []);

  //Used to maintain history of chat flow in local storage
  useEffect(() => {
    if (location.pathname.includes("create")) {
      if (
        questions.length > 1 ||
        agents.length > 1 ||
        departments.length > 1 ||
        Object.keys(settingsData).length > 1
      ) {
        localStorage.setItem(
          "chatFlowHistory",
          JSON.stringify({
            questions,
            agents,
            departments,
            settingsData,
            tabValue,
          })
        );
      }
    }
  }, [questions, agents, departments, settingsData, tabValue]);

  // Fetching chatflow if id exists in url meaning it is view or update page
  useEffect(() => {
    if (id) {
      dispatch(Actions.flowLoading(true));
      dispatch(Actions.getFlow(id));
      setQuestions([null]);
      if (location.pathname.includes("update")) {
        setPageType("Update");
      } else if (location.pathname.includes("view")) {
        setPageType("View");
      }
    }
    if (location.pathname.includes("create")) {
      dispatch(Actions.configLoading(true));
      setShowPrompt(true);
      dispatch(Actions.getConfig());
    }
  }, [id, location.pathname]);

  useEffect(() => {
    if (generalConfigReducer && generalConfigReducer.config) {
      setSettingsData({ ...settingsData, config: generalConfigReducer.config });
    }
  }, [generalConfigReducer.config]);

  useEffect(() => {
    if (
      chatFlowReducer &&
      chatFlowReducer.flowDetail &&
      chatFlowReducer.stateLoading === false &&
      pageType !== "Add"
    ) {
      debugger;
      setSettingsData({
        description: chatFlowReducer.flowDetail.description,
        title: chatFlowReducer.flowDetail.title,
        customerId: chatFlowReducer.flowDetail.customerId,
        config: chatFlowReducer.flowDetail.config,
        startWithFirstQuestion:
          chatFlowReducer.flowDetail.config.startWithFirstQuestion,
        default: chatFlowReducer.flowDetail.default,
      });
      setQuestions(chatFlowReducer.flowDetail.queries);
      setDepartments(chatFlowReducer.flowDetail.departments);
      setAgents(chatFlowReducer.flowDetail.humanAgents);
    }
  }, [chatFlowReducer, pageType]);

  if (chatFlowReducer && chatFlowReducer.error) {
    return (
      <div
        className="page-loader d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "300px" }}
      >
        <h2>Error! Cannot fetch chat flow detail</h2>
        <Button
          color="danger"
          className="mt-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
    );
  }
  if (
    chatFlowReducer === null ||
    chatFlowReducer.loading ||
    generalConfigReducer.loading
  ) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <h2 className="mb-4">{pageType} Flow</h2>
      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              key={index + 1}
              onClick={(e) => {
                if (allowNext === true) {
                  setTabValue(index);
                }
              }}
              className={`${
                index === tabValue
                  ? "bg-primary text-white "
                  : "flow-inactive-tab"
              }  flow-tab mt-2 cursor-pointer mr-4`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {tabValue === 0 ? (
          <div className="">
            <Settings
              settingsData={settingsData}
              setSettingsData={setSettingsData}
              allowNext={allowNext}
              setAllowNext={setAllowNext}
              setTabValue={setTabValue}
              pageType={pageType}
              {...props}
            />
          </div>
        ) : tabValue === 1 ? (
          <div className="">
            <FlowBuilder
              agents={agents}
              setAgents={setAgents}
              departments={departments}
              setDepartments={setDepartments}
              questions={questions}
              setQuestions={setQuestions}
              settingsData={settingsData}
              id={id}
              pageType={pageType}
              setShowPrompt={setShowPrompt}
              {...props}
            />
          </div>
        ) : null}
        <ConfirmDialogue
          open={historyDialogue}
          handleClose={() => {
            localStorage.setItem("chatFlowHistory", null);
            setHistoryDialogue(false);
          }}
          action={() => {
            let result = JSON.parse(localStorage.getItem("chatFlowHistory"));
            setSettingsData({ ...settingsData, ...result.settingsData });
            setQuestions(result.questions);
            setDepartments(result.departments);
            setAgents(result.agents);
            if (result.tabValue) {
              setAllowNext(true);
            }
            setTabValue(result.tabValue);
            setHistoryDialogue(false);
          }}
          msg="Use previous uncompleted chat flow?"
        />
      </div>
    </div>
  );
}

export default Index;
