import * as Actions from '../actions'

const initialState = {
  loading: false,
  stateLoading: false,
  flowsList: null,
  flowVersions: null,
  flowDetail: null,
  flowsCount: null,

  error: null
}

const flowReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FLOW_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case Actions.FLOW_STATE_LOADING: {
      return {
        ...state,
        stateLoading: action.payload
      }
    }
    case Actions.FLOW_ERROR: {
      return {
        ...state,
        loading: false,
        stateLoading: false,
        error: action.payload
      }
    }
    case Actions.GET_FLOWS: {
      return {
        ...state,
        flowsList: action.payload.chatFlows,
        flowsCount: action.payload.meta.totalCount,
        flowDetail: null,
        loading: false,
        stateLoading: false,
        error: null
      }
    }
    case Actions.GET_FLOW_VERSIONS: {
      return {
        ...state,
        flowVersions: action.payload,
        error: null,
        stateLoading: false
      }
    }
    case Actions.FLOW_VERSIONS_NULL: {
      return {
        ...state,
        flowVersions: null
      }
    }
    case Actions.GET_FLOW: {
      return {
        ...state,
        flowDetail: action.payload,
        loading: false,
        error: null
      }
    }

    default: {
      return state
    }
  }
}
export default flowReducer
