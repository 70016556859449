import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useNavigate } from "react-router-dom";
import * as Actions from "store/actions";

import TimerIcon from "@material-ui/icons/Timer";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CachedIcon from "@material-ui/icons/Cached";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { CircularProgress } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "reactstrap";
import { NotificationManager } from "react-notifications";

import "./index.css";

// import IconButton from "@material-ui/core/IconButton";

function GeneralConfiguration(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let [configuration, setConfiguration] = useState();

  const generalConfigReducer = useSelector(
    ({ generalConfig }) => generalConfig
  );

  useEffect(() => {
    if (generalConfigReducer) {
      dispatch(Actions.configLoading(true));
      dispatch(Actions.getConfig());
    }
    return function cleanup() {
      dispatch(Actions.setConfigNull());
    };
  }, []);

  useEffect(() => {
    if (generalConfigReducer && generalConfigReducer.config) {
      setConfiguration(generalConfigReducer.config);
    }
  }, [generalConfigReducer.config]);

  let handleSubmit = (e) => {
    e.preventDefault();
    let obj = configuration;
    if (obj.__v) {
      delete obj.__v;
    }
    if (obj._id) {
      delete obj._id;
    }
    dispatch(Actions.configStateLoading(true));
    dispatch(Actions.updateConfig(obj, navigate));
  };
  if (generalConfigReducer && generalConfigReducer.error) {
    return (
      <div
        className="page-loader d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "300px" }}
      >
        <h2>Error! Cannot fetch general configuration</h2>
        <Button
          color="danger"
          className="mt-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
    );
  }
  if (generalConfigReducer === null || generalConfigReducer.loading) {
    return <CircularProgressComponent />;
  }

  let handleInputChange = (e) => {
    if (e.target.name === "restartFlow") {
      setConfiguration({ ...configuration, [e.target.name]: true });
    }
    if (e.target.name === "restartFirstQuestion") {
      setConfiguration({ ...configuration, [e.target.name]: true });
    }
    if (
      e.target.name !== "restartFlow" &&
      e.target.name !== "restartFirstQuestion"
    ) {
      setConfiguration({ ...configuration, [e.target.name]: e.target.value });
    }
  };

  return (
    <div>
      <h2 className="mb-4">General Configuration</h2>
      <div>
        <div className="rct-block pd-top-40">
          <div className="pt-50 pb-50 w-100 ">
            <form
              onSubmit={handleSubmit}
              className="w-75 m-auto text-center settings-form"
            >
              <div className="m-auto text-center ">
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginRight: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    type="number"
                    id="outlined-required"
                    label="No of retries"
                    variant="outlined"
                    fullWidth
                    name="retryLimit"
                    value={configuration && configuration?.retryLimit}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CachedIcon />
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginLeft: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    type="number"
                    id="outlined-required"
                    label="Session Timeout Duration"
                    variant="outlined"
                    fullWidth
                    name="sessionExpiry"
                    value={configuration && configuration?.sessionExpiry}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TimerIcon />
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>

              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    id="outlined-required"
                    label="Error Message"
                    variant="outlined"
                    fullWidth
                    name="fallBackError"
                    multiline
                    rows={4}
                    value={configuration && configuration?.fallBackError}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    id="outlined-required"
                    label="Hangup Message"
                    variant="outlined"
                    fullWidth
                    name="hangUpMessage"
                    multiline
                    rows={4}
                    value={configuration && configuration?.hangUpMessage}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    id="outlined-required"
                    label="End Flow Message"
                    variant="outlined"
                    fullWidth
                    name="endFlowMessage"
                    multiline
                    rows={4}
                    value={configuration && configuration?.endFlowMessage}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    // required
                    id="outlined-required"
                    label="End Flow Error Message (optional)"
                    variant="outlined"
                    fullWidth
                    placeholder="optional for now..."
                    name="endFlowErrorMessage"
                    multiline
                    rows={4}
                    value={configuration && configuration?.endFlowErrorMessage}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div
                className="mx-auto text-left"
                style={{ width: "82.5%", maxWidth: "830px" }}
              >
                <input
                  type="checkbox"
                  id="restart_first_question"
                  name="restartFirstQuestion"
                  value={configuration && configuration?.restartFlow}
                  checked={configuration && configuration?.restartFirstQuestion}
                  onChange={handleInputChange}
                  className="mr-2"
                  style={{ transform: "scale(1.2)" }}
                />
                <label htmlFor="start_again" style={{ fontSize: "1rem" }}>
                  Start with first questions option (optional)
                </label>
              </div>
              <div
                className="mx-auto text-left"
                style={{ width: "82.5%", maxWidth: "830px" }}
              >
                <input
                  type="checkbox"
                  id="restartflow"
                  name="restartFlow"
                  value={configuration && configuration?.restartFlow}
                  checked={configuration && configuration?.restartFlow}
                  onChange={handleInputChange}
                  className="mr-2"
                  style={{ transform: "scale(1.2)" }}
                />
                <label htmlFor="restartflow" style={{ fontSize: "1rem" }}>
                  Restart chatflow (optional)
                </label>
              </div>
              <div className="text-right mt-40">
                <div
                  className="mx-auto"
                  style={{ width: "82.5%", maxWidth: "830px" }}
                >
                  <button
                    type="submit"
                    className="btn bg-primary text-white px-4 py-2"
                    disabled={generalConfigReducer.stateLoading}
                  >
                    {generalConfigReducer.stateLoading ? (
                      <CircularProgress size={16} style={{ color: "white" }} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <h2 className="mb-4">Api Token</h2>
      <div>
        <div className="rct-block pd-top-40 p-4">
          {generalConfigReducer && generalConfigReducer.apiKey != null ? (
            <div>
              <h2>One time generated key</h2>
              <p
                className=""
                style={{ wordWrap: "break-word", fontSize: "18px" }}
              >
                {generalConfigReducer.apiKey.toString()}
              </p>

              <CopyToClipboard
                text={generalConfigReducer.apiKey.toString()}
                onCopy={() =>
                  NotificationManager.success("Copied to clipboard")
                }
              >
                <button className="btn bg-primary text-white px-4 py-2">
                  Copy to clipboard{" "}
                </button>
              </CopyToClipboard>
            </div>
          ) : (
            <button
              className="btn bg-primary text-white px-4 py-2"
              onClick={() => {
                dispatch(Actions.configGenerateLoading(true));
                dispatch(Actions.generateApiToken());
              }}
            >
              {generalConfigReducer.generateLoading ? (
                <CircularProgress size={16} style={{ color: "white" }} />
              ) : (
                "Generate"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default GeneralConfiguration;
