import React from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

function HumanAgentCard(props) {
  let renderAgents = () => {
    if (props.agents && props.agents.length !== 0) {
      return (
        props.agents &&
        props.agents.map((agent, index) => {
          return (
            <div className="row mb-2 ">
              <div className="col-sm-4">
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Id : {agent.id}
                </span>
              </div>
              <div className="col-sm-6">
                <span
                  className="mr-2"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Name : {agent.name}
                </span>
              </div>
              <div className="col-sm-2 d-flex">
                <button
                  type="button"
                  className="delete-btn mx-2"
                  onClick={() => {
                    props.handleEditAgent(index);
                  }}
                  disabled={props.pageType === "View" ? true : false}
                >
                  <AiFillEdit className="cursor-pointer" />
                </button>
                <button
                  type="button"
                  className="delete-btn"
                  onClick={() => {
                    props.handleRemoveAgent(index);
                  }}
                  disabled={props.pageType === "View" ? true : false}
                >
                  <AiFillDelete className="cursor-pointer" />
                </button>
              </div>
            </div>
          );
        })
      );
    } else {
      return (
        <div className="text-center mt-4 ">
          <span>No Agents exist</span>
        </div>
      );
    }
  };
  return (
    <div className=" p-4 w-100 ">
      <div>
        <div className=" d-flex justify-content-between p-0 ">
          <h4 className="align-self-end ">Human Agent</h4>
          <button
            type="button"
            className="btn bg-primary text-white px-3 py-1"
            onClick={() => {
              props.setAgentModal(true);
            }}
            disabled={props.pageType === "View" ? true : false}
          >
            Add Agent
          </button>
        </div>
        <div className="mt-4">{renderAgents()}</div>
      </div>
    </div>
  );
}

export default HumanAgentCard;
