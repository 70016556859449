import React from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

function DepartmentCard(props) {
  let renderDepartments = () => {
    if (props.departments && props.departments.length !== 0) {
      return props.departments.map((department, index) => {
        return (
          <div className="row mb-2 ">
            <div className="col-sm-4">
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Id : {department.id}
              </span>
            </div>
            <div className="col-sm-6">
              <span
                className="mr-2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Name : {department.name}
              </span>
            </div>
            {/*  */}
            <div className="col-sm-2 d-flex">
              <button
                type="button"
                className="delete-btn mx-2"
                disabled={props.pageType === "View" ? true : false}
                onClick={() => {
                  props.handleEditDepartment(index);
                }}
              >
                <AiFillEdit className="cursor-pointer" />
              </button>
              <button
                type="button"
                className="delete-btn"
                disabled={props.pageType === "View" ? true : false}
                onClick={() => {
                  props.handleRemoveDepartment(index);
                }}
              >
                <AiFillDelete className="cursor-pointer" />
              </button>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="text-center  mt-4">
          <span>No Departments exist</span>
        </div>
      );
    }
  };
  return (
    <div className=" p-4 w-100 ">
      <div>
        <div className=" d-flex justify-content-between p-0 ">
          <h4 className="align-self-end ">Department</h4>
          <button
            type="button"
            className="btn bg-primary text-white px-3 py-1"
            onClick={() => {
              props.setDepartmentModal(true);
            }}
            disabled={props.pageType === "View" ? true : false}
          >
            Add Department
          </button>
        </div>
        <div className="mt-4">{renderDepartments()}</div>
      </div>
    </div>
  );
}

export default DepartmentCard;
