import React from "react";

import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  AiOutlineCloseCircle,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";

const OptionModal = ({
  open,
  toggle,
  linkModalData,
  questions,
  setQuestions,
  pageType,
}) => {
  const [resIdentifier, setResIdentifier] = React.useState(false);
  // Reference to question itself
  let questionRef =
    linkModalData && questions[linkModalData.questionIndex]
      ? questions[linkModalData.questionIndex]
      : null;
  // Reference to inputType data which exists in case of input type question
  let optionObj =
    linkModalData && questions[linkModalData.questionIndex]
      ? questions[linkModalData.questionIndex].options[
          linkModalData.optionIndex
        ]
      : null;

  let linkModalItem = linkModalData && linkModalData.item;

  // The response text for response text field
  let responseText = linkModalData && linkModalData.item.responseText;

  // File link input only allowed if link type is link-to-question or file-link
  let renderFileLinkInput = () => {
    if (linkModalData && linkModalData.item) {
      if (
        linkModalData.item.linkType === "Link-To-Question" ||
        linkModalData.item.linkType === "File-Link"
      ) {
        return (
          <div className="mt-4">
            <Label className="d-flex">File Link</Label>
            <Input
              type="text"
              name="fileLink"
              placeholder="File Link"
              required
              value={`${
                linkModalData.item.fileLink ? linkModalData.item.fileLink : ""
              }`}
              onChange={(e) => {
                changeValue(e.target.value, "fileLink");
              }}
              disabled={pageType === "View" ? true : false}
            />
          </div>
        );
      } else {
        return null;
      }
    }
  };

  // Rendering Api Node when there is externalApi object inside  question.options[0] == inputTypeDataRef
  let renderApiNode = () => {
    if (
      questionRef &&
      linkModalItem.linkType === "Link-To-Question" &&
      optionObj &&
      "externalApi" in linkModalItem
    ) {
      return (
        <div>
          <div className="mt-4">
            <Label className="d-flex">API URL</Label>
            <Input
              type="text"
              name="url"
              placeholder="API Url"
              required
              value={`${
                optionObj.externalApi?.url ? optionObj.externalApi.url : ""
              }`}
              onChange={(e) => {
                changeApiNodeValue(e.target.value, "url");
              }}
              disabled={pageType === "View" ? true : false}
            />
          </div>
          <div className="d-flex mt-4">
            <div className="flex-1 w-50">
              <Label className="d-flex">Authentication Key</Label>
              <Input
                type="text"
                name="authKey"
                placeholder="Authentication Key"
                required
                value={`${
                  optionObj.externalApi?.authKey
                    ? optionObj.externalApi.authKey
                    : ""
                }`}
                onChange={(e) => {
                  changeApiNodeValue(e.target.value, "authKey");
                }}
                disabled={pageType === "View" ? true : false}
              />
            </div>
            <div className="ml-4 flex-1 w-50">
              <Label className="d-flex">Authentication Token</Label>
              <Input
                type="text"
                name="authToken"
                placeholder="Authentication Token"
                required
                value={`${
                  optionObj.externalApi?.authToken
                    ? optionObj.externalApi.authToken
                    : ""
                }`}
                onChange={(e) => {
                  changeApiNodeValue(e.target.value, "authToken");
                }}
                disabled={pageType === "View" ? true : false}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start">
            <div className="flex-1 w-50 flex-column mt-4 ">
              <Label className="d-flex">API response</Label>
              <div className="d-flex mt-1">
                <input
                  type="checkbox"
                  id="canStoreResponse"
                  name="canStoreResponse"
                  checked={
                    optionObj.externalApi
                      ? optionObj.externalApi.canStoreResponse
                      : resIdentifier
                  }
                  onChange={(e) =>
                    changeApiNodeValue(e.target.checked, "canStoreResponse")
                  }
                  className=""
                  style={{ transform: "scale(1.2)" }}
                  disabled={pageType === "View" ? true : false}
                />
                <label
                  className="mb-0 ml-2 text-center d-flex justify-content-center align-content-center align-items-center"
                  htmlFor="canStoreResponse"
                  style={{ fontSize: "1rem" }}
                >
                  Save response
                </label>
              </div>
            </div>
            {resIdentifier || optionObj.externalApi?.canStoreResponse ? (
              <div className="flex-1 w-50 ml-4 flex-column mt-4 w-full">
                <Label className="d-flex">Response identifier</Label>
                <Input
                  type="text"
                  name="variableName"
                  placeholder="Response identifier"
                  required={resIdentifier ? true : false}
                  value={`${
                    optionObj.externalApi?.variableName
                      ? optionObj.externalApi.variableName
                      : ""
                  }`}
                  onChange={(e) => {
                    changeApiNodeValue(e.target.value, "variableName");
                  }}
                  disabled={pageType === "View" ? true : false}
                />
              </div>
            ) : null}
          </div>

          <div className="d-flex justify-content-start mt-4">
            <div className="flex-1 w-50 ">
              <Label className="d-flex">API Method</Label>
              <Input
                type="select"
                name="method"
                placeholder="API Method"
                required
                value={`${optionObj.externalApi?.method}`}
                onChange={(e) => {
                  changeApiNodeValue(e.target.value, "method");
                }}
                disabled={pageType === "View" ? true : false}
              >
                <option value="POST">Post</option>
                <option value="GET">Get</option>
                <option value="PUT">Put</option>
                <option value="PATCH">Patch</option>
              </Input>
            </div>
            {optionObj.externalApi?.method === "POST" ||
            optionObj.externalApi?.method === "PUT" ||
            optionObj.externalApi?.method === "PATCH" ? (
              <div className=" ml-4 flex-1 w-50">
                <Label className="d-flex">Data Type</Label>
                <Input
                  type="select"
                  name="dataMode"
                  required
                  value={`${optionObj.externalApi?.dataMode}`}
                  onChange={(e) => {
                    optionObj.externalApi.params = [];
                    changeApiNodeValue(e.target.value, "dataMode");
                  }}
                  disabled={pageType === "View" ? true : false}
                >
                  <option value="Form-Data">Query Params</option>
                  <option value="Query-String">Query String</option>
                  <option value="Raw-Json">Raw Json</option>
                </Input>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };
  // Rendering Params
  let renderParams = () => {
    if (linkModalData && optionObj) {
      let paramRef = optionObj ? optionObj.externalApi?.params : null;
      return (
        paramRef &&
        paramRef.map((param, index) => {
          return (
            <div className="d-flex  align-items-center justify-content-between my-2">
              <Input
                type="text"
                id="key"
                placeholder="Key"
                value={param.key}
                onChange={(e) => {
                  param.key = e.target.value;
                  setQuestions([...questions]);
                }}
                className="w-50 mr-4"
              />
              <Input
                type="select"
                className="w-50 mr-4"
                value={param.value}
                onChange={(e) => {
                  param.value = e.target.value;
                  setQuestions([...questions]);
                }}
              >
                <option value={""}>Select Identifier</option>
                {questions.map((question) => {
                  if (question.type === "Input") {
                    return (
                      <option value={question.options[0].statement}>
                        {question.options[0].statement}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </Input>
              <AiOutlineMinusCircle
                className="cursor-pointer"
                style={{ fontSize: "20px" }}
                onClick={() => {
                  removeApiNodeParam(index);
                }}
              />
            </div>
          );
        })
      );
    }
  };
  // Changing input values
  let changeValue = (value, fieldName) => {
    optionObj[fieldName] = value;
    setQuestions([...questions]);
  };
  // Changing api node values
  let changeApiNodeValue = (value, fieldName) => {
    if (fieldName === "canStoreResponse") {
      setResIdentifier(!resIdentifier);
    }
    optionObj.externalApi[fieldName] = value;
    setQuestions([...questions]);
  };
  let addApiNode = () => {
    optionObj.externalApi = {
      uuid: uuidv4(),
      params: [],
      method: "POST",
      dataMode: "Form-Data",
      canStoreResponse: resIdentifier,
    };
    optionObj.useExternalApi = true;
    setQuestions([...questions]);
  };
  let removeApiNode = () => {
    optionObj.useExternalApi = false;
    delete optionObj.externalApi;
    setQuestions([...questions]);
  };
  let addApiNodeParam = () => {
    optionObj.externalApi.params.push({
      key: "",
      value: null,
    });
    setQuestions([...questions]);
  };
  let removeApiNodeParam = (index) => {
    if (linkModalData) {
      questions[
        linkModalData.questionIndex
      ].options[0].externalApi.params.splice(index, 1);
      setQuestions([...questions]);
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} className="modal-background">
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <AiOutlineCloseCircle
                onClick={() => toggle()}
                className="cursor-pointer"
                style={{ fontSize: "24px" }}
              />
            </div>
            <div className="mt-1 form-styles">
              <div className="mt-4">
                <Label className="d-flex">Response</Label>
                <Input
                  name="responseText"
                  placeholder="Response Text"
                  type="textarea"
                  rows="4"
                  value={`${responseText}`}
                  required
                  onChange={(e) => {
                    changeValue(e.target.value, "responseText");
                  }}
                  disabled={pageType === "View" ? true : false}
                />
              </div>
              {renderFileLinkInput()}
              {renderApiNode()}
              {optionObj &&
              "externalApi" in optionObj &&
              linkModalItem.linkType === "Link-To-Question" &&
              optionObj.externalApi?.dataMode !== "Raw-Json" ? (
                <div>
                  <div className="d-flex justify-content-between my-4  w-100">
                    <h4>Add Params</h4>
                    <AiOutlinePlusCircle
                      className="cursor-pointer"
                      style={{ fontSize: "20px" }}
                      onClick={addApiNodeParam}
                    />
                  </div>
                  {renderParams()}
                </div>
              ) : null}
              {optionObj && optionObj.externalApi?.dataMode === "Raw-Json" ? (
                <div className="mt-4">
                  <Label className="d-flex">Raw Json</Label>
                  <Input
                    name="rawData"
                    placeholder="Raw Json"
                    type="textarea"
                    rows="4"
                    value={`${
                      optionObj.externalApi?.rawData
                        ? optionObj.externalApi.rawData
                        : ""
                    }`}
                    required
                    onChange={(e) => {
                      changeApiNodeValue(e.target.value, "rawData");
                    }}
                    disabled={pageType === "View" ? true : false}
                  />
                </div>
              ) : null}

              <div className="mt-4 d-flex justify-content-end">
                {linkModalData &&
                linkModalItem.linkType === "Link-To-Question" ? (
                  <Button
                    type="button"
                    className="bg-primary text-white px-4 mr-4"
                    color="none"
                    onClick={() => {
                      optionObj && "externalApi" in optionObj
                        ? removeApiNode()
                        : addApiNode();
                    }}
                    disabled={pageType === "View" ? true : false}
                  >
                    {optionObj && !("externalApi" in optionObj)
                      ? "Add Api Node"
                      : "Remove Api Node"}
                  </Button>
                ) : null}

                <Button
                  type="button"
                  className="bg-primary text-white px-4"
                  color="none"
                  onClick={() => toggle()}
                  disabled={pageType === "View" ? true : false}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OptionModal;
