import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export const GET_FLOWS = "[ADMIN] GET_FLOWS";
export const GET_FLOW = "[ADMIN] GET_FLOW";
export const GET_FLOW_VERSIONS = "[ADMIN] GET_FLOW_VERSIONS";
export const FLOW_VERSIONS_NULL = "[ADMIN] FLOW_VERSIONS_NULL";
export const FLOW_STATE_LOADING = "[ADMIN] FLOW_STATE_LOADING";
export const FLOW_LOADING = "[ADMIN] FLOW_LOADING";
export const FLOW_ERROR = "[ADMIN] FLOW_ERROR";

export function getFlows(variables) {
  let pg = 1;
  if (variables && variables.pg) {
    pg = variables.pg;
    delete variables.pg;
  }
  const request = axios.get(`${Domain}/crm/api/chatflow/all`, {
    params: {
      ...variables,
      pg: pg,
      pp: 10,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_FLOWS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FLOW_ERROR,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error! Cannot get chat flows",
        });
      });
}
export function getFlowVersions(id) {
  const request = axios.get(`${Domain}/crm/api/chatflow/versions/${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_FLOW_VERSIONS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FLOW_ERROR,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error! Cannot get chat flow detail",
        });
      });
}
export function getFlow(id) {
  const request = axios.get(`${Domain}/crm/api/chatflow/${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_FLOW,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FLOW_ERROR,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error! Cannot get chat flow detail",
        });
      });
}
export function setFlowVersionsNull() {
  return (dispatch) => {
    return dispatch({
      type: FLOW_VERSIONS_NULL,
    });
  };
}
export function addFlow(data, navigate) {
  // let navigate = useNavigate();
  debugger;
  const request = axios.post(`${Domain}/crm/api/chatflow`, data);
  return (dispatch) =>
    request
      .then((resp) => {
        NotificationManager.success("Chat flow added successfully");
        dispatch(flowLoading(true));
        dispatch(getFlows());
        navigate("/admin/flows");
      })
      .catch((error) => {
        dispatch(flowStateLoading(false));
        error.response
          ? NotificationManager.error(error.response.data.message)
          : NotificationManager.error("Error! Cannot add chat flow");
      });
}
export function updateFlow(data, type, navigate) {
  const request = axios.put(
    `${Domain}/crm/api/chatflow/${type === "copy" ? "copy" : "edit"}`,
    data
  );
  return (dispatch) =>
    request
      .then((resp) => {
        NotificationManager.success("Chat flow upated successfully");
        dispatch(flowStateLoading(false));
        dispatch(flowLoading(true));
        dispatch(getFlows());
        navigate("/admin/flows");
      })
      .catch((error) => {
        dispatch(flowStateLoading(false));
        error.response
          ? NotificationManager.error(error.response.data.message)
          : NotificationManager.error("Error! Cannot update chat flow");
      });
}

export function deleteFlow(flowId, variables, type) {
  const request = axios.delete(`${Domain}/crm/api/chatflow`, {
    data: { id: flowId },
  });

  return (dispatch) =>
    request
      .then((resp) => {
        NotificationManager.success("Chat Flow deleted successfully");
        if (type === "detail") {
          dispatch(flowStateLoading(true));
          dispatch(getFlowVersions(flowId));
        } else {
          dispatch(getFlows(variables));
        }
      })
      .catch((error) => {
        dispatch(flowLoading(false));
        NotificationManager.error("Error! Unable to delete chat flow");
      });
}

export function flowStateLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: FLOW_STATE_LOADING,
      payload: val,
    });
  };
}

export function flowLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: FLOW_LOADING,
      payload: val,
    });
  };
}
