import React, { useRef, useEffect, useState } from "react";
import { Input } from "reactstrap";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import McqTypeQuestion from "./questionType/mcqType";
import InputTypeQuestion from "./questionType/inputType";
import ChangeQuestion from "components/Modals/changeQuestion";

function SingleChatQuestion(props) {
  const lastRef = useRef(null);
  const [subTypes, setSubTypes] = useState("");
  const [statementError, setStatementError] = useState(false);
  const [questionNumbering, setQuestionNumbering] = useState(true);
  const [ChnageQuestionDialog, setChangeQuestionDialog] = useState({
    bool: false,
    value: null,
  });

  useEffect(() => {
    if (lastRef.current) lastRef.current.focus();
  }, [props.questions.length]);

  useEffect(() => {
    if (props.question.errorMessage) {
      setQuestionNumbering(!questionNumbering);
    }
    if (props.question.errorMessage) {
      setStatementError(!statementError);
    }
  }, [props.questions.errorMessage]);

  let changeQuestionType = (value) => {
    if (value === "MCQ") {
      delete props.questions[props.questionIndex].errorMessage;
      delete props.questions[props.questionIndex].subType;
      delete props.questions[props.questionIndex].inputFieldFormat;
    }
    if (value === "Input") {
      delete props.questions[props.questionIndex].errorMessage;
    }
    props.questions[props.questionIndex] = {
      ...props.questions[props.questionIndex],
      type: value,
      options: [
        {
          displayOrder: 1,
          statement: "",
          linkType: "Link-To-Question",
          linkId: "",
          responseText: "",
          uuid: uuidv4(),
          useExternalApi: false,
        },
      ],
    };
    props.setQuestions([...props.questions]);

    setChangeQuestionDialog({ bool: false, value: null });
  };

  var wasNull = false;
  for (var i = 0; i < props.question?.options.length; i++) {
    if (
      props.question?.options[i].useExternalApi === false &&
      props.question?.options[i].statement === ""
    ) {
      wasNull = true;
      break;
    }
  }
  // if (wasNull) {
  //   console.log("null");
  // } else {
  //   console.log("not null");
  // }

  return (
    <div className="col-md-12 py-2 px-4  ">
      <div className="d-flex justify-content-end p-0 m-0 ">
        <div className=" bg-white px-4 p-3 w-auto mr-4 question_addDel_tab">
          <button
            type="button"
            className="delete-btn"
            disabled={props.pageType === "View" ? true : false}
            onClick={() => {
              // Only allow deleting question if there is more than 1
              if (props.questions.length > 1) {
                props.removeQuestion(props.questionIndex);
              }
            }}
          >
            <AiFillDelete
              className={`mr-4 cursor-pointer  ${
                props.questions.length === 1 ? "color-light" : null
              }`}
            />
          </button>
          <button
            type="button"
            className="delete-btn"
            disabled={props.pageType === "View" ? true : false}
          >
            <AiFillPlusCircle
              className="ml-4 cursor-pointer"
              onClick={() => {
                props.addQuestion(props.questionIndex);
              }}
            />
          </button>
        </div>
      </div>
      <div className="rct-block p-4 position-relative  ">
        <div className="border-bottom pb-1 w-100 mb-4 ">
          <div className="d-flex justify-content-between">
            <h2 className="mb-3">Question {props.questionIndex + 1}</h2>
            <div
              style={
                props.question.type === "MCQ"
                  ? { maxWidth: 800, width: "100%" }
                  : { maxWidth: 824, width: "100%" }
              }
              className="d-flex"
            >
              {props.question.type === "MCQ" && (
                <div
                  style={{ maxWidth: 400, width: "100%" }}
                  className="d-flex justify-content-between align-content-end"
                >
                  <div
                    // style={{ marginRight: "1rem" }}
                    className="d-flex justify-content-end"
                  >
                    <input
                      type="checkbox"
                      id="question_numbering"
                      name="questionNumbering"
                      onClick={() => setQuestionNumbering(!questionNumbering)}
                      checked={questionNumbering}
                      // onChange={() => {
                      //   props.questions[props.questionIndex] = {
                      //     ...props.questions[props.questionIndex],
                      //     [e.target.name]: questionNumbering,
                      //   };
                      //   props.setQuestions([...props.questions]);
                      // }}
                      className="mr-2"
                      disabled={props.pageType === "View" ? true : false}
                      style={{ transform: "scale(1.2)" }}
                    />
                    <label
                      className="mb-0 mr-2 text-center d-flex justify-content-center align-content-center align-items-center"
                      htmlFor="question_numbering"
                      style={{ fontSize: "1rem", textAlign: "center" }}
                    >
                      Show Numbering
                    </label>
                  </div>
                  <div
                    style={{ marginRight: "1rem" }}
                    className="d-flex justify-content-end"
                  >
                    <input
                      type="checkbox"
                      id="errorMessageState"
                      name="errorMessageState"
                      onClick={() => setStatementError(!statementError)}
                      checked={statementError}
                      // onChange={(e) => {
                      //   props.questions[props.questionIndex] = {
                      //     ...props.questions[props.questionIndex],
                      //     [e.target.name]: statementError,
                      //   };
                      //   props.setQuestions([...props.questions]);
                      // }}
                      className="mr-2"
                      disabled={props.pageType === "View" ? true : false}
                      style={{ transform: "scale(1.2)" }}
                    />
                    <label
                      className="mb-0  mr-xl-4 text-center d-flex justify-content-center align-content-center align-items-center"
                      htmlFor="errorMessage"
                      style={{ fontSize: "1rem" }}
                    >
                      Add Error Message
                    </label>
                  </div>
                </div>
              )}
              {props.question.type === "Input" && (
                <div
                  style={{
                    maxWidth: 400,
                    width: "100%",
                    marginRight: "1.5rem",
                  }}
                  className="d-flex"
                >
                  <Input
                    type="select"
                    name="linkType"
                    required
                    value={`${props.question.subType}`}
                    onChange={(e) => {
                      props.questions[props.questionIndex] = {
                        ...props.questions[props.questionIndex],
                        subType: e.target.value,
                      };
                      props.setQuestions([...props.questions]);
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  >
                    <option defaultValue value="">
                      Select...
                    </option>
                    <option value="Date">Date</option>
                    <option value="Time">Time</option>
                    <option value="Phone">Phone</option>
                    <option value="Email">Email</option>
                    <option value="FreeText">Free Text</option>
                  </Input>
                </div>
              )}
              <div style={{ maxWidth: 400, width: "100%" }} className="d-flex">
                <Input
                  type="select"
                  name="linkType"
                  required
                  value={`${props.question.type}`}
                  onChange={
                    wasNull
                      ? (e) => changeQuestionType(e.target.value)
                      : (e) =>
                          setChangeQuestionDialog({
                            bool: true,
                            value: e.target.value,
                          })
                  }
                  disabled={props.pageType === "View" ? true : false}
                >
                  <option value="MCQ">MCQ</option>
                  <option value="Input">Input Type</option>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "end",
              justifyContent: "end",
              alignItems: "end",
              marginTop: "10px",
              width: "100%",
              gap: "1.5rem",
            }}
          >
            {props.question.type === "MCQ" && (
              <div style={{ maxWidth: 400, width: "100%" }}>
                {statementError && (
                  <Input
                    type="text"
                    name="linkType"
                    placeholder="Error Message"
                    value={
                      props.question.errorMessage
                        ? `${props.question.errorMessage}`
                        : ""
                    }
                    required
                    onChange={(e) => {
                      props.questions[props.questionIndex] = {
                        ...props.questions[props.questionIndex],
                        errorMessage: e.target.value,
                      };
                      props.setQuestions([...props.questions]);
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                )}
              </div>
            )}

            {props.question.type === "MCQ" && (
              <div style={{ maxWidth: 400, width: "100%" }}>
                <Input
                  type="text"
                  placeholder="identifier..."
                  name="linkType"
                  // required
                  value={props?.question?.variableName}
                  onChange={(e) => {
                    props.questions[props.questionIndex] = {
                      ...props.questions[props.questionIndex],
                      variableName: e.target.value,
                    };
                    props.setQuestions([...props.questions]);
                  }}
                  disabled={props.pageType === "View" ? true : false}
                />
              </div>
            )}

            {props.question.subType && props.question.type === "Input" && (
              <div
                style={{
                  display: "flex",
                  gap: "1.5rem",
                  width: "100%",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{ maxWidth: 400, width: "100%" }}
                  className="d-flex"
                >
                  {props.question.subType === "Date" ||
                  props.question.subType === "Time" ||
                  subTypes ? (
                    <Input
                      type="text"
                      placeholder={`Enter ${props.question.subType} Format`}
                      name="linkType"
                      required
                      value={props.question.inputFieldFormat}
                      onChange={(e) => {
                        props.questions[props.questionIndex] = {
                          ...props.questions[props.questionIndex],
                          inputFieldFormat: e.target.value,
                        };
                        props.setQuestions([...props.questions]);
                      }}
                      disabled={props.pageType === "View" ? true : false}
                    />
                  ) : null}
                </div>
                <div style={{ maxWidth: 400, width: "100%" }}>
                  {props.question.type === "Input" &&
                  props.question.subType !== "FreeText" ? (
                    <Input
                      type="text"
                      name="linkType"
                      placeholder="Validation Error Message"
                      value={props.question.errorMessage}
                      required
                      onChange={(e) => {
                        props.questions[props.questionIndex] = {
                          ...props.questions[props.questionIndex],
                          errorMessage: e.target.value,
                        };
                        props.setQuestions([...props.questions]);
                      }}
                      disabled={props.pageType === "View" ? true : false}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <input
            id="question_title"
            type="textarea"
            name="statement"
            required
            className="w-100 mt-4 border-0 bg-transparent questionaire-title font-weight-bold mb-2"
            placeholder={`${"question starts here"}...`}
            rows={1}
            value={props.question.statement}
            // Used to focusing when a new question gets added
            ref={
              props.newlyAddedQuestionIndex === props.questionIndex
                ? lastRef
                : undefined
            }
            onChange={(e) => {
              props.questions[props.questionIndex] = {
                ...props.questions[props.questionIndex],
                statement: e.target.value,
              };
              props.setQuestions([...props.questions]);
            }}
            disabled={props.pageType === "View" ? true : false}
          />
        </div>
        <div>
          {props.question.type === "MCQ" ? (
            <McqTypeQuestion
              questionNumbering={questionNumbering}
              questions={props.questions}
              setQuestions={props.setQuestions}
              questionIndex={props.questionIndex}
              question={props.question}
              renderDynamicDropdown={props.renderDynamicDropdown}
              pageType={props.pageType}
              setLinkModalData={props.setLinkModalData}
              setLinkModal={props.setLinkModal}
            />
          ) : (
            <InputTypeQuestion
              questions={props.questions}
              setQuestions={props.setQuestions}
              questionIndex={props.questionIndex}
              question={props.question}
              renderDynamicDropdown={props.renderDynamicDropdown}
              pageType={props.pageType}
              setLinkModalData={props.setLinkModalData}
              setLinkModal={props.setLinkModal}
            />
          )}
        </div>
      </div>
      <ChangeQuestion
        open={ChnageQuestionDialog}
        toggle={setChangeQuestionDialog}
        changeQuestionType={changeQuestionType}
      />
    </div>
  );
}

export default SingleChatQuestion;
