import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import TimerIcon from "@material-ui/icons/Timer";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import InfoIcon from "@material-ui/icons/Info";
import PersonIcon from "@material-ui/icons/Person";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CachedIcon from "@material-ui/icons/Cached";

import "./index.css";

function Settings(props) {
  useEffect(() => {
    if (props.allowNext === true) {
      props.setAllowNext(false);
    }
  }, [props]);
  let handleSubmit = (e) => {
    e.preventDefault();
    props.setAllowNext(true);
    props.setTabValue(1);
  };

  return (
    <div>
      <div>
        <div className="rct-block pd-top-40">
          <div className="pt-50 pb-50 w-100 ">
            <form
              onSubmit={handleSubmit}
              className="w-75 m-auto text-center settings-form"
            >
              <div className="text-center">
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginRight: "15px",
                  }}
                  className="mb-40  d-inline-block"
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name="title"
                    value={props.settingsData && props.settingsData.title}
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginLeft: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    name="description"
                    value={props.settingsData && props.settingsData.description}
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InfoIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="m-auto text-center ">
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginRight: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    type="number"
                    required
                    id="outlined-required"
                    label="No of retries"
                    variant="outlined"
                    fullWidth
                    name="retryLimit"
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.retryLimit
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CachedIcon />
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginLeft: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Customer Id"
                    variant="outlined"
                    fullWidth
                    name="customerId"
                    value={props.settingsData && props.settingsData.customerId}
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginRight: "15px",
                  }}
                  className="mb-40  d-inline-block"
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Hangup Agent Id"
                    variant="outlined"
                    fullWidth
                    name="fallBackAgentId"
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.fallBackAgentId
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>

                <div
                  style={{
                    width: "40%",
                    maxWidth: "400px",
                    marginLeft: "15px",
                  }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    type="number"
                    id="outlined-required"
                    label="Session Timeout Duration"
                    variant="outlined"
                    fullWidth
                    name="sessionExpiry"
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.sessionExpiry
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TimerIcon />
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Error Message"
                    variant="outlined"
                    fullWidth
                    name="fallBackError"
                    multiline
                    rows={4}
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.fallBackError
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Hangup Message"
                    variant="outlined"
                    fullWidth
                    name="hangUpMessage"
                    multiline
                    rows={4}
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.hangUpMessage
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="End Flow Message"
                    variant="outlined"
                    fullWidth
                    name="endFlowMessage"
                    multiline
                    rows={4}
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.endFlowMessage
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="text-center">
                <div
                  style={{ width: "82.5%", maxWidth: "830px" }}
                  className="mb-40  d-inline-block "
                >
                  <TextField
                    // required
                    id="outlined-required"
                    label="End Flow Error Message (optional)"
                    variant="outlined"
                    placeholder="optional for now..."
                    fullWidth
                    name="endFlowErrorMessage"
                    multiline
                    rows={4}
                    value={
                      props.settingsData &&
                      props.settingsData.config &&
                      props.settingsData.config.endFlowErrorMessage
                    }
                    onChange={(e) => {
                      props.setSettingsData({
                        ...props.settingsData,
                        config: {
                          ...props.settingsData.config,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReportProblemIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={props.pageType === "View" ? true : false}
                  />
                </div>
              </div>
              <div
                className="mx-auto text-left"
                style={{ width: "82.5%", maxWidth: "830px" }}
              >
                <input
                  type="checkbox"
                  id="startWithFirstQuestion"
                  name="startWithFirstQuestion"
                  checked={
                    props.settingsData &&
                    props.settingsData.startWithFirstQuestion
                  }
                  onChange={(e) => {
                    props.setSettingsData({
                      ...props.settingsData,
                      config: {
                        ...props.settingsData.config,
                        [e.target.name]: e.target.checked,
                      },
                      [e.target.name]: e.target.checked,
                    });
                  }}
                  className="mr-2"
                  style={{ transform: "scale(1.2)" }}
                  disabled={props.pageType === "View" ? true : false}
                />
                <label
                  htmlFor="startWithFirstQuestion"
                  style={{ fontSize: "1rem" }}
                >
                  Start with first questions option
                </label>
              </div>
              <div
                className="mx-auto text-left"
                style={{ width: "82.5%", maxWidth: "830px" }}
              >
                <input
                  type="checkbox"
                  id="restartFlow"
                  name="restartFlow"
                  checked={props.settingsData && props.settingsData.restartFlow}
                  onChange={(e) => {
                    props.setSettingsData({
                      ...props.settingsData,
                      restartFlow: e.target.checked,
                    });
                  }}
                  className="mr-2"
                  style={{ transform: "scale(1.2)" }}
                  disabled={props.pageType === "View" ? true : false}
                />
                <label htmlFor="restartflow" style={{ fontSize: "1rem" }}>
                  Restart chatflow (optional)
                </label>
              </div>
              <div
                className="mx-auto text-left"
                style={{ width: "82.5%", maxWidth: "830px" }}
              >
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={props.settingsData && props.settingsData.default}
                  onChange={(e) => {
                    props.setSettingsData({
                      ...props.settingsData,
                      default: e.target.checked,
                    });
                  }}
                  className="mr-2"
                  style={{ transform: "scale(1.2)" }}
                  disabled={props.pageType === "View" ? true : false}
                />
                <label htmlFor="scales" style={{ fontSize: "1rem" }}>
                  Set as default chatflow
                </label>
              </div>
              <div className="text-right  ">
                <div
                  className="mx-auto"
                  style={{ width: "82.5%", maxWidth: "830px" }}
                >
                  <button className="btn bg-primary text-white px-4 border border-dark">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
