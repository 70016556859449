import React from 'react'
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer'

export default function CustomEdge ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId
}) {
  let srcPostion = 'left'
  let trgPosition = 'right'
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    srcPostion,
    targetX,
    targetY,
    trgPosition
  })
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

  return (
    <>
      <path
        id={id}
        style={style}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      <text>
        <textPath
          href={`#${id}`}
          //   style={{ fontSize: "12px", margin: "10px" }}
          startOffset='45%'
          //   textAnchor="middle"
        >
          {data?.text}
        </textPath>
      </text>
    </>
  )
}
