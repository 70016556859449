import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function CircularProgressComponent() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "400px" }}
    >
      <CircularProgress />
    </div>
  );
}
