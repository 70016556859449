import * as Actions from '../actions'

const initialState = {
  loading: false,
  configLoading: false,
  generateLoading: false,
  stateLoading: false,
  config: null,
  apiKey: null,
  error: null
}

const configReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CONFIG_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case Actions.CONFIG_GENERATE_LOADING: {
      return {
        ...state,
        generateLoading: action.payload
      }
    }
    case Actions.CONFIG_STATE_LOADING: {
      return {
        ...state,
        stateLoading: action.payload
      }
    }
    case Actions.CONFIG_ERROR: {
      return {
        ...state,
        loading: false,
        stateLoading: false,
        generateLoading: false,
        error: action.payload
      }
    }
    case Actions.CONFIG_GENERATE_ERROR: {
      return {
        ...state,
        generateLoading: false,
        apiKey: null
      }
    }
    case Actions.CONFIG_NULL: {
      return {
        ...state,
        config: null
      }
    }
    case Actions.GET_CONFIG: {
      return {
        ...state,
        config: action.payload,
        loading: false,
        stateLoading: false,
        error: null
      }
    }
    case Actions.GET_API_KEY: {
      return {
        ...state,
        generateLoading: false,
        apiKey: action.payload
      }
    }

    default: {
      return state
    }
  }
}
export default configReducer
