import React, { useState, useEffect } from "react";

import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
} from "react-flow-renderer";
import dagre from "dagre";
import { v4 as uuidv4 } from "uuid";
import { Dialog, AppBar, Toolbar, IconButton, Slide } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CustomEdge from "./customEdge";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 175;
  const getLayoutedElements = (elements, direction = "LR") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });
    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);
    return elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? "left" : "top";
        el.sourcePosition = isHorizontal ? "right" : "bottom";

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }

      return el;
    });
  };
  const position = { x: 0, y: 0 };
  let questionStyle = {
    background: "#263e60",
    color: "#fff",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "7px",
    padding: "3px",
    height: "90px",
    width: "225px",
  };
  let inputQuestionStyle = {
    background: "#4a79bd",
    color: "#fff",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "7px",
    padding: "3px",
    height: "90px",
    width: "225px",
  };
  let answerStyle = {
    background: "#D0FFD0",
    color: "#111",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "7px",
    padding: "5px",
    height: "90px",
    width: "235px",
  };
  const edgeType = "straight";
  const edgeTypes = {
    custom: CustomEdge,
  };
  const stringTruncate = (str, length) => {
    if (str && length) {
      const dots = str.length > length ? "....." : "";
      return `${str.substring(0, length)}${dots}`;
    }
  };

  let connectionToQuestion = (questionOption, question, graphArray) => {
    let checkResponseAvailable = false;
    let labelInserted = false;
    let checkExternalApi = false;

    // Creating a connection from question to Response Text
    if (questionOption.responseText) {
      checkResponseAvailable = true;
      labelInserted = true;
      graphArray.push({
        id: uuidv4(),
        source: question.uuid,
        target: questionOption?.uuid,
        type: edgeType,
        animated: false,
        label: questionOption.statement,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
      });
    }
    // Creating a connection from response text to file link
    let checkFileAvailable = false;
    if (questionOption.fileLink) {
      checkFileAvailable = true;
      labelInserted = true;
      graphArray.push({
        id: uuidv4(),
        source: questionOption?.uuid,
        target: questionOption?.uuid + "File-Link-Question",
        type: edgeType,
        animated: false,
        label: labelInserted === true ? null : questionOption.statement,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
      });
    }
    if ("externalApi" in questionOption) {
      checkExternalApi = true;
      let nodeSource = checkFileAvailable
        ? questionOption?.uuid + "File-Link-Question"
        : checkResponseAvailable
        ? questionOption?.uuid
        : question.uuid;
      graphArray.push({
        id: uuidv4(),
        source: nodeSource,
        target: questionOption?.externalApi.uuid,
        // type: "custom",
        animated: false,
        // data: { text: labelInserted == true ? null : questionOption.statement },
        label: labelInserted === true ? null : questionOption.statement,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
      });
    }
    let finalSourceNode = checkExternalApi
      ? questionOption?.externalApi.uuid
      : checkFileAvailable
      ? questionOption?.uuid + "File-Link-Question"
      : checkResponseAvailable
      ? questionOption?.uuid
      : question.uuid;
    // Creating a connection from fileLink/response text to question
    graphArray.push({
      id: uuidv4(),
      //  Checking if File Link is present then connecting from file link to question
      //  Else checking if response is available the connecting response to question
      //  If both are false then directly connecting the question to question
      source: finalSourceNode,
      target: questionOption.linkId,
      // type: "custom",
      animated: false,
      // data: { text: labelInserted == true ? null : questionOption.statement },
      label: labelInserted === true ? null : questionOption.statement,
      labelBgPadding: [8, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
    });
  };
  let generalConnection = (
    questionOption,
    question,
    graphArray,
    connectionType
  ) => {
    let checkResponseAvailable = false;
    let labelInserted = false;
    if (questionOption.responseText) {
      checkResponseAvailable = true;
      labelInserted = true;
      graphArray.push({
        id: uuidv4(),
        source: question.uuid,
        target: questionOption?.uuid,
        type: edgeType,
        animated: false,
        label: questionOption.statement,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
      });
    }
    graphArray.push({
      id: uuidv4(),
      source: checkResponseAvailable ? questionOption?.uuid : question.uuid,
      target: questionOption?.uuid + connectionType,
      type: edgeType,
      animated: false,
      label: labelInserted === true ? null : questionOption.statement,
      labelBgPadding: [8, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
    });
  };
  let addGraphNode = (
    graphArray,
    id,
    title,
    text,
    position,
    style,
    stringLength
  ) => {
    graphArray.push({
      id: id,
      data: {
        label: (
          <div>
            <h3>{title}</h3>
            <p>{stringTruncate(text, stringLength)}</p>
          </div>
        ),
      },
      position,
      style: style,
    });
  };

  useEffect(() => {
    if (props.dialogState) {
      let questions = props.questions;
      let graphArray = [];
      // Creating Nodes and Connection for Graph
      questions.map((question, index) => {
        // Creating Question Node
        addGraphNode(
          graphArray,
          question.uuid,
          `Question ${index + 1}`,
          question.statement,
          position,
          question.type === "Input" ? inputQuestionStyle : questionStyle,
          65
        );

        // Creating other Nodes
        if (question.options) {
          question.options.map((questionOption, index) => {
            if (questionOption.responseText) {
              addGraphNode(
                graphArray,
                questionOption?.uuid,
                `Response`,
                questionOption.responseText,
                position,
                answerStyle,
                65
              );
            }
            if (
              questionOption.fileLink &&
              questionOption.linkType === "Link-To-Question"
            ) {
              addGraphNode(
                graphArray,
                questionOption?.uuid + "File-Link-Question",
                `File Link`,
                questionOption.fileLink,
                position,
                answerStyle,
                65
              );
            }
            if (
              questionOption.fileLink &&
              questionOption.linkType === "File-Link"
            ) {
              addGraphNode(
                graphArray,
                questionOption?.uuid + "File-Link-Normal",
                `File Link`,
                questionOption.fileLink,
                position,
                answerStyle,
                65
              );
            }
            if (
              questionOption.linkType === "Human-Agent" &&
              questionOption.linkId
            ) {
              let agent = props.agents.filter(
                (agent) => agent.id === questionOption.linkId
              );
              let text = null;
              if (agent) {
                text = `(${agent[0]?.id}) ${agent[0]?.name}`;
              }
              addGraphNode(
                graphArray,
                questionOption?.uuid + "Human-Agent",
                `Human Agent`,
                text,
                position,
                answerStyle,
                65
              );
            }
            if (
              questionOption.linkType === "Department" &&
              questionOption.linkId
            ) {
              let department = props.departments.filter(
                (department) => department.id === questionOption.linkId
              );
              let text = null;
              if (department) {
                text = `(${department[0]?.id}) ${department[0]?.name}`;
              }
              addGraphNode(
                graphArray,
                questionOption?.uuid + "Department",
                `Department`,
                text,
                position,
                answerStyle,
                65
              );
            }
            if (questionOption.linkType === "End-Flow") {
              addGraphNode(
                graphArray,
                questionOption?.uuid + "End-Flow",
                `End Flow`,
                null,
                position,
                answerStyle,
                65
              );
            }
            if ("externalApi" in questionOption) {
              addGraphNode(
                graphArray,
                questionOption?.externalApi.uuid,
                "API",
                questionOption?.externalApi?.url,
                position,
                answerStyle,
                35
              );
            }
            return null;
          });
        } else if (
          question.type === "Input" &&
          question.options[0] &&
          "externalApi" in question.options[0]
        ) {
          addGraphNode(
            graphArray,
            question.options[0]?.externalApi.uuid,
            "API",
            question.options[0]?.externalApi?.url,
            position,
            answerStyle,
            65
          );
        }
        return null;
      });
      // Creating Connections
      questions.map((question, index) => {
        if (
          // question.type === 'MCQ' &&
          question.options &&
          question.options.length > 0
        ) {
          question.options.map((questionOption, index) => {
            if (questionOption.linkType) {
              if (
                questionOption.linkType === "Link-To-Question" &&
                questionOption.linkId
              ) {
                connectionToQuestion(questionOption, question, graphArray);
              } else if (questionOption.linkType === "Human-Agent") {
                generalConnection(
                  questionOption,
                  question,
                  graphArray,
                  "Human-Agent"
                );
              } else if (questionOption.linkType === "Department") {
                generalConnection(
                  questionOption,
                  question,
                  graphArray,
                  "Department"
                );
              } else if (questionOption.linkType === "File-Link") {
                generalConnection(
                  questionOption,
                  question,
                  graphArray,
                  "File-Link-Normal"
                );
              } else if (questionOption.linkType === "End-Flow") {
                generalConnection(
                  questionOption,
                  question,
                  graphArray,
                  "End-Flow"
                );
              }
            }
            return null;
          });
          return null;
        }

        return null;
      });

      const layoutedElements = getLayoutedElements(graphArray);
      setElements(layoutedElements);
    }
  }, [props.dialogState]);

  const [elements, setElements] = useState([]);
  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: "smoothstep", animated: false }, els)
    );
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  // const onLayout = useCallback(
  //   direction => {
  //     const layoutedElements = getLayoutedElements(elements, direction)
  //     setElements(layoutedElements)
  //   },
  //   [elements]
  // )
  const graphStyles = {
    width: "100%",
    // height: "80vh",
    // top: "10vh",
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.dialogState}
        onClose={props.toggle}
        TransitionComponent={Transition}
      >
        <AppBar className="bg-primary">
          <Toolbar className="bg-primary">
            <IconButton
              color="inherit"
              onClick={props.toggle}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>

            <h5 className="w-100 mb-0 ml-2 fw-bold">Chat Flow</h5>
          </Toolbar>
        </AppBar>
        <div
          className=""
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            border: "1px solid black",
            width: "100%",
            margin: "auto",
            padding: "10px",
            height: "93%",
          }}
        >
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              connectionLineType="smoothstep"
              style={graphStyles}
              edgeTypes={edgeTypes}
            />
          </ReactFlowProvider>
        </div>
      </Dialog>
    </div>
  );
}
