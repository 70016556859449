import { combineReducers } from "redux";
import auth from "../../auth/store/reducers";
import appReducer from "./app.reducer";
import chatFlow from "./chatFlow.reducer";
import generalConfig from "./generalConfig.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    appReducer,
    chatFlow,
    generalConfig,
    ...asyncReducers,
  });

export default createReducer;
