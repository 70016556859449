import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import { Form, FormGroup, Input } from 'reactstrap'
import * as AuthAction from 'auth/store/actions'
import './loginStyle.css'
import 'animate.css'

function AdminLogin () {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const loading = useSelector(({ auth }) => auth.login.loading)
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  // const Auth = true;

  useEffect(() => {
    if (Auth && Auth.role === 'Admin') {
      navigate('/admin/flows')
    }
  }, [Auth, navigate])
  const onSubmitLogin = e => {
    e.preventDefault()
    let data = {
      email: e.target.email.value,
      password: e.target.password.value
    }
    dispatch(AuthAction.loginLoading(true))
    dispatch(AuthAction.loginAdmin(data))
  }

  return (
    <div>
      {loading && <LinearProgress />}
      <div className='main-container'>
        <div className='bg-div bg-primary'>
          <div className='bg-content animated bounceInLeft text-center'>
            <div className='mb-6'>
              <img
                src={require('assets/img/main_logo.png')}
                className='img-fluid'
                alt='site-logo'
                width='220'
              />
            </div>
            {/* <h6 className="lorem-h6">Kavaret Chat Builder</h6> */}
          </div>
        </div>
        <div className='login-panel'>
          <div className='login-content'>
            <div className='tabs-div'>
              <h2 className='text-center title-login mb-4 '>Login</h2>
              <h4 className='text-center mb-4  primary-color '>
                Enter your Login and Password
              </h4>
              <Form onSubmit={e => onSubmitLogin(e)}>
                <FormGroup className='has-wrapper'>
                  <span className='has-icon'>
                    <i className='ti-user'></i>
                  </span>
                  <Input
                    type='email'
                    name='email'
                    id='user-mail'
                    className='has-input input-lg theinputs'
                    placeholder='Enter Email'
                    required
                  />
                </FormGroup>
                <FormGroup className='has-wrapper'>
                  <span className='has-icon'>
                    <i className='ti-lock'></i>
                  </span>
                  <Input
                    type='Password'
                    name='password'
                    id='pwd'
                    className='has-input input-lg theinputs'
                    placeholder='Password'
                    required
                    minLength='8'
                  />
                </FormGroup>
                <FormGroup className='mb-15'>
                  <Button
                    type='submit'
                    className=' text-white w-100 bg-primary rounded'
                    variant='contained'
                    size='large'
                    disabled={loading}
                  >
                    Log In
                  </Button>
                </FormGroup>
              </Form>
              {/* <div className="text-center">
                <a href={Domain} style={{ color: "#192d3e" }}>
                  Go back home?
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdminLogin
