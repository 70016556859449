import React from "react";
import { Input } from "reactstrap";
import { AiFillDelete } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

const McqType = ({
  questionNumbering,
  question,
  questions,
  setQuestions,
  questionIndex,
  renderDynamicDropdown,
  pageType,
  setLinkModalData,
  setLinkModal,
}) => {
  return (
    <div>
      <div className="mb-40">
        {question.options &&
          question.options.map((option, optionIndex) => {
            return (
              <div className="row mb-3  px-30 ">
                <div className="col-md-3 d-flex align-items-center">
                  <span className="mr-2">
                    {questionNumbering ? <>{optionIndex + 1}.</> : null}
                  </span>
                  <Input
                    type="text"
                    className="w-100 border-0  bg-transparent d-inline-block "
                    placeholder={`${"Option"} `}
                    name="statement"
                    value={`${option.statement}`}
                    required
                    onChange={(e) => {
                      questions[questionIndex].options[optionIndex].statement =
                        e.target.value;
                      setQuestions([...questions]);
                    }}
                    disabled={pageType === "View" ? true : false}
                  />
                </div>
                <div className="col-md-9 d-flex  justify-content-end ">
                  <Input
                    style={{ maxWidth: 300, width: "100%" }}
                    type="select"
                    name="linkType"
                    required
                    className="mr-4 "
                    value={`${option.linkType}`}
                    onChange={(e) => {
                      let optionRef =
                        questions[questionIndex].options[optionIndex];
                      optionRef.linkType = e.target.value;
                      optionRef.linkId = "";
                      optionRef.fileLink = "";
                      if (e.target.value !== "Link-To-Question") {
                        optionRef.useExternalApi = false;
                        if (optionRef.externalApi) {
                          delete optionRef.externalApi;
                        }
                      }
                      setQuestions([...questions]);
                    }}
                    disabled={pageType === "View" ? true : false}
                  >
                    <option value="Link-To-Question">Link to question</option>
                    <option value="Human-Agent">Human Agent</option>
                    <option value="Department">Department</option>
                    <option value="End-Flow">End Flow</option>
                    <option value="File-Link">File Link</option>
                  </Input>

                  {renderDynamicDropdown(questionIndex, optionIndex)}
                  <button
                    type="button"
                    className="btn text-primary px-4 bg-white border border-dark mr-4"
                    onClick={() => {
                      setLinkModal(true);
                      setLinkModalData({
                        item: questions[questionIndex].options[optionIndex],
                        questionIndex: questionIndex,
                        optionIndex: optionIndex,
                        type: "MCQ",
                      });
                    }}
                  >
                    Link
                  </button>
                  {/* One Option is compulsory that is why no allowind delete on first option index */}
                  {optionIndex !== 0 ? (
                    <button
                      type="button"
                      disabled={pageType === "View" ? true : false}
                      className="align-self-center cursor-pointer delete-btn"
                      onClick={() => {
                        questions[questionIndex].options.splice(optionIndex, 1);
                        let arr = [...questions[questionIndex].options];
                        for (let i = 0; i < arr.length; i++) {
                          arr[i].displayOrder = i + 1;
                        }
                        setQuestions([...questions]);
                      }}
                    >
                      <AiFillDelete />
                    </button>
                  ) : (
                    <div style={{ visibility: "hidden" }}>
                      <AiFillDelete />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div>
        <button
          type="button"
          disabled={pageType === "View" ? true : false}
          onClick={(e) => {
            let _lastDisplayOrder = questions[questionIndex].options.slice(-1);
            let displayOrderNumber = _lastDisplayOrder[0].displayOrder + 1;
            questions[questionIndex].options = [
              ...questions[questionIndex].options,
              {
                displayOrder: displayOrderNumber,
                statement: "",
                linkType: "Link-To-Question",
                linkId: "",
                responseText: "",
                value: false,
                uuid: uuidv4(),
              },
            ];
            setQuestions([...questions]);
          }}
          className="btn text-primary px-4 bg-white border border-dark"
        >
          Add Option
        </button>
      </div>
    </div>
  );
};

export default McqType;
