import axios from "axios";
import jwtDecode from "jwt-decode";
import Utils from "./eventemitter";
import Domain from "lib/Config";

class jwtService extends Utils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            err.response.status === 401
            //  &&
            // err.config &&
            // !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, first revalidate then logout
            let user = JSON.parse(window.localStorage.getItem("user"));
            let refresh_token = window.localStorage.getItem("refresh_token");
            if (user && user.email && refresh_token) {
              this.signInWithToken(user?.email, refresh_token)
                .then((user) => {})
                .catch((error) => {
                  this.emit("onAutoLogout", "Invalid access_token");
                  this.setSession(null);
                });
            } else {
              this.emit("onAutoLogout", "Invalid access_token");
              this.setSession(null);
            }
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();
    let refresh_token = this.getRefreshToken();
    if (!access_token || !refresh_token) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      // let user = window.localStorage.getItem('user')
      // var parsedUser = null
      // if (user !== null || user !== undefined) {
      //   // user = JSON.parse(user);
      //   parsedUser = JSON.parse(user)
      // }
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };
  // line 84 refrestToken
  signInAdmin = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Domain}/crm/login`, {
          email,
          password,
        })
        .then((response) => {
          if (response.data.status === "Success") {
            this.setSession(
              response.data.data.token,
              response.data.data.refreshToken,
              {
                role: "Admin",
                email: email,
              }
            );
            resolve({ role: "Admin", email: email });
          } else {
            if (response.data.message) {
              reject(response.data.message);
            } else {
              reject("Something went wrong");
            }
          }
        })
        .catch((error) => {
          return error.response && error.response.data.message
            ? reject(error.response.data.message)
            : reject("Network Error");
        });
    });
  };

  // line 124 refrestToken
  signInWithToken = (email, refreshToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Domain}/crm/update-token`, {
          email,
          refreshToken,
        })
        .then((response) => {
          if (response.data.status === "Success") {
            let user = JSON.parse(window.localStorage.getItem("user"));
            let access_token = response.data.data.token;
            let refresh_token = response.data.data.refreshToken;
            this.setSession(access_token, refresh_token, user);
            resolve(user);
          } else {
            reject("Validation Failed");
          }
        })
        .catch((error) => {
          reject("Validation Failed");
        });
    });
  };

  setSession = (access_token, refresh_token, user) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("user", JSON.stringify(user));
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("refresh_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
  getRefreshToken = () => {
    return window.localStorage.getItem("refresh_token");
  };
}

const instance = new jwtService();

export default instance;
