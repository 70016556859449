import HumanAgentModal from "components/Modals/humanAgent";
import HumanAgentCard from "./dataCards/humanAgentCard";
import LinkModal from "components/Modals/link";
import DepartmentModal from "components/Modals/department";
import DepartmentCard from "./dataCards/departmentCard";
import SingleChatQuestion from "./singleChatQuestion";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import * as Actions from "store/actions";
import { Input } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { CircularProgress } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import TreeView from "./treeView/treeView";

function FlowBuilder(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [agentModal, setAgentModal] = useState(false);
  let [agentModalData, setAgentModalData] = useState(null);

  let [departmentModal, setDepartmentModal] = useState(false);
  let [departmentModalData, setDepartmentModalData] = useState(null);

  // Link Modal used for  additional information regarding an option
  let [linkModal, setLinkModal] = useState(false);

  let [linkModalData, setLinkModalData] = useState(null);
  let [treeView, setTreeView] = useState(false);

  // Used for reference ---> so whenever new question gets added we can focus on it.
  let [newlyAddedQuestionIndex, setNewlyAddedQuestionIndex] = useState(null);

  // A new version of chatflow could be created or previous could be updated so using this state to check.
  let [formSubmitType, setFormSubmitType] = useState(null);

  useEffect(() => {
    if (!agentModal) {
      setAgentModalData(null);
    }
    if (!departmentModal) {
      setDepartmentModalData(null);
    }
  }, [agentModal, departmentModal]);

  const chatFlowReducer = useSelector(({ chatFlow }) => chatFlow);
  let addQuestion = (index) => {
    props.questions.splice(index + 1, 0, {
      uuid: uuidv4(),
      type: "MCQ",
      displayOrder: index + 2,
      statement: "",
      options: [
        {
          displayOrder: 1,
          statement: "",
          linkType: "Link-To-Question",
          linkId: "",
          responseText: "",
          uuid: uuidv4(),
          fileLink: "",
        },
      ],
    });
    setNewlyAddedQuestionIndex(index + 1);
    props.setQuestions([...props.questions]);
  };
  let removeQuestion = (index) => {
    // executes if the question of type input is deleted
    // change the value of params to empty strings to prevent the submision of form
    if (props.questions[index].type == "Input") {
      let dltdQuesIdentifier = props.questions[index].options[0].statement;
      if (index != null) {
        const arrMap = props.questions.flatMap((m) => m?.options);
        arrMap.forEach(function (item, index) {
          let allParamsArr = item?.externalApi?.params;

          let paramsMap = allParamsArr?.map((item) => {
            if (
              item?.value !== undefined &&
              item?.value == dltdQuesIdentifier
            ) {
              item.value = "";
            }
          });
        });
      }
    }

    let removedElement = props.questions.splice(index, 1);
    let removedElementUUID = removedElement[0].uuid;
    // Setting question linkId  value to null which were linked to the question which is removed.
    if (props.questions) {
      let updatedquestionArray = props.questions.map((question, index) => {
        if (question.options) {
          question.options.map((option, index) => {
            if (option.linkId === removedElementUUID) {
              option.linkId = "";
            }
            return option;
          });
        }
        return question;
      });
      props.setQuestions([...updatedquestionArray]);
    }
  };

  let addAgent = (agent) => {
    props.setAgents([...props.agents, agent]);
  };

  let editAgent = (agent) => {
    let agentArr = props.agents;
    const index = agentModalData?.index;
    if (agentArr) agentArr[index] = agent;
    props.setAgents([...agentArr]);

    const arrMap = props.questions.flatMap((m) => m?.options);

    arrMap.forEach(function (item, index) {
      if (item?.linkId == agentModalData?.data?.id) {
        item.linkId = agent.id;
      }
    });
  };

  let addDepartment = (department) => {
    props.setDepartments([...props.departments, department]);
  };

  let editDepartment = (departments) => {
    let departmentArr = props.departments;
    const index = departmentModalData?.index;
    if (departmentArr) departmentArr[index] = departments;
    props.setDepartments([...departmentArr]);
    const arrMap = props.questions.flatMap((m) => m?.options);
    arrMap.forEach(function (item, index) {
      if (item?.linkId == departmentModalData?.data?.id) {
        item.linkId = departments.id;
      }
    });
  };

  let handleRemoveAgent = (index) => {
    if (props.questions) {
      let agent = props.agents.splice(index, 1);
      let updatedquestionArray = props.questions.map((question, index) => {
        if (question.options) {
          question.options.map((option, index) => {
            if (
              option.linkType === "Human-Agent" &&
              option.linkId === agent[0].id
            ) {
              option.linkId = null;
            }
            return option;
          });
        }
        return question;
      });
      props.setAgents([...props.agents]);
      props.setQuestions([...updatedquestionArray]);
    }
  };

  let handleEditAgent = (index) => {
    setAgentModal(true);
    setAgentModalData({ data: props.agents[index], index: index });
  };

  let handleEditDepartment = (index) => {
    setDepartmentModal(true);
    setDepartmentModalData({ data: props.departments[index], index: index });
  };

  let handleRemoveDepartment = (index) => {
    if (props.questions) {
      let department = props.departments.splice(index, 1);
      let updatedquestionArray = props.questions.map((question, index) => {
        if (question.options) {
          question.options.map((option, index) => {
            if (
              option.linkType === "Department" &&
              option.linkId === department[0].id
            ) {
              option.linkId = null;
            }
            return option;
          });
        }
        return question;
      });
      props.setDepartments([...props.departments]);
      props.setQuestions([...updatedquestionArray]);
    }
  };
  let renderDynamicDropdown = (questionIndex, optionIndex, type) => {
    let questionRef = props.questions[questionIndex];
    let reference = {
      "Human-Agent": props.agents,
      Department: props.departments,
    };
    let selectedLinkageType =
      type === "input"
        ? questionRef.options[0].linkType
        : questionRef.options[optionIndex].linkType;
    let itemRef =
      type === "input"
        ? questionRef.options[0]
        : questionRef.options[optionIndex];

    if (
      selectedLinkageType === "Human-Agent" ||
      selectedLinkageType === "Department"
    ) {
      return (
        <Input
          style={{ maxWidth: 300, width: "100%" }}
          type="select"
          name="linkId"
          required
          className="mr-4"
          value={`${itemRef.linkId}`}
          onChange={(e) => {
            itemRef.linkId = e.target.value;
            props.setQuestions([...props.questions]);
          }}
          disabled={props.pageType === "View" ? true : false}
        >
          <option value="" defaultValue>
            {`Select ${selectedLinkageType}`}
          </option>
          {reference[selectedLinkageType].map((user) => {
            return <option value={user.id}>{user.name}</option>;
          })}
        </Input>
      );
    } else if (selectedLinkageType === "Link-To-Question") {
      return (
        <Input
          style={{ maxWidth: 300, width: "100%" }}
          type="select"
          name="linkId"
          required
          className="mr-4 "
          value={`${itemRef.linkId}`}
          onChange={(e) => {
            itemRef.linkId = e.target.value;
            props.setQuestions([...props.questions]);
          }}
          disabled={props.pageType === "View" ? true : false}
        >
          <option
            value=""
            disabled
            defaultValue
            hidden
          >{`Select Question`}</option>
          {props.questions.map((question, index) => {
            return <option value={question.uuid}>Question {index + 1}</option>;
          })}
        </Input>
      );
    }
  };
  let handleFormSubmit = (e) => {
    e.preventDefault();
    // Checking if there api url is missing or param if yes prevent submission
    let errorCheck = props.questions.forEach((question) => {
      if (question.options) {
        return question.options.some((option) => {
          if (option.externalApi) {
            if (
              !("url" in option.externalApi) ||
              option.externalApi.url.length === 0
            ) {
              NotificationManager.error("Api Url is missing");
              return true;
            }
            if (
              option.externalApi.params &&
              option.externalApi.params.length > 0
            ) {
              return option.externalApi.params.some((param) => {
                if (!param.key.length === 0 || param.value === "") {
                  NotificationManager.error("Params is required");

                  return true;
                }
              });
            }
          }
        });
      }
    });
    if (!errorCheck) {
      debugger;
      // Final Object as required by the backend api
      let finalObj = {
        title: props.settingsData.title,
        description: props.settingsData.description,
        customerId: props.settingsData.customerId,
        default: props.settingsData.default ? true : false,
        config: props.settingsData.config,
        startWithFirstQuestion: props.settingsData.startWithFirstQuestion,
        humanAgents: props.agents,
        departments: props.departments,
        queries: props.questions,
        version: chatFlowReducer.flowDetail?.version.substring(1),
      };
      // props.setShowPrompt(false)

      dispatch(Actions.flowStateLoading(true));
      if (props.pageType === "Update") {
        finalObj.id = props.id;
        dispatch(Actions.updateFlow(finalObj, formSubmitType, navigate));
      } else {
        dispatch(Actions.addFlow(finalObj, navigate));
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div className="page-title d-flex justify-content-end align-items-center">
          {/* A form element's default button is the first submit button in tree order whose form owner is that form element. */}
          {/* Using this submit button to prevent submitting on enter press it is not visible but exists in dom */}
          <button
            type="submit"
            disabled
            style={{ display: "none" }}
            aria-hidden="true"
          ></button>
          <div>
            <div
              className="btn bg-primary mr-4 px-4 py-1 "
              onClick={() => {
                setTreeView(true);
              }}
            >
              <AiFillEye
                className="text-white  pt-1"
                style={{ fontSize: "22px" }}
              />
            </div>

            <div className=" d-inline-block">
              {/* In case of view mode no submission button to be displayed */}
              {props.pageType !== "View" ? (
                <>
                  {/* In case of Update Mode show save as copy and normal submit button */}
                  {props.pageType !== "Add" ? (
                    <button
                      type="submit"
                      id="submitUpdate"
                      className="btn bg-primary text-white px-4 py-2 mr-4"
                      disabled={chatFlowReducer.stateLoading}
                      onClick={() => {
                        setFormSubmitType("copy");
                      }}
                    >
                      {chatFlowReducer.stateLoading &&
                      formSubmitType === "copy" ? (
                        <CircularProgress
                          size={16}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "Save as new version"
                      )}
                    </button>
                  ) : null}
                  <button
                    type="submit"
                    id="submitUpdate"
                    className="btn bg-primary text-white px-4 py-2"
                    disabled={chatFlowReducer.stateLoading}
                    onClick={() => {
                      setFormSubmitType("update");
                    }}
                  >
                    {chatFlowReducer.stateLoading &&
                    formSubmitType === "update" ? (
                      <CircularProgress size={16} style={{ color: "white" }} />
                    ) : (
                      props.pageType
                    )}
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row pd-top-40">
          <div className="col-md-6 p-4">
            <div className="rct-block  d-flex ">
              <HumanAgentCard
                agents={props.agents}
                setAgents={props.setAgents}
                setAgentModal={setAgentModal}
                handleRemoveAgent={handleRemoveAgent}
                handleEditAgent={handleEditAgent}
                pageType={props.pageType}
              />
            </div>
          </div>
          <div className="col-md-6 p-4">
            <div className="rct-block  d-flex ">
              <DepartmentCard
                departments={props.departments}
                setDepartments={props.setDepartments}
                setDepartmentModal={setDepartmentModal}
                handleRemoveDepartment={handleRemoveDepartment}
                handleEditDepartment={handleEditDepartment}
                pageType={props.pageType}
              />
            </div>
          </div>
        </div>
        <div className="row pd-top-20">
          {props.questions &&
            props.questions.map((question, questionIndex) => {
              return (
                <SingleChatQuestion
                  question={question}
                  questionIndex={questionIndex}
                  pageType={props.pageType}
                  removeQuestion={removeQuestion}
                  addQuestion={addQuestion}
                  questions={props.questions}
                  setQuestions={props.setQuestions}
                  renderDynamicDropdown={renderDynamicDropdown}
                  linkModalData={linkModalData}
                  setLinkModalData={setLinkModalData}
                  linkModal={linkModal}
                  setLinkModal={setLinkModal}
                  newlyAddedQuestionIndex={newlyAddedQuestionIndex}
                />
              );
            })}
        </div>
      </form>

      <HumanAgentModal
        open={agentModal}
        toggle={() => {
          setAgentModal(!agentModal);
        }}
        agentModalData={agentModalData}
        addAgent={addAgent}
        editAgent={editAgent}
      />
      <DepartmentModal
        open={departmentModal}
        toggle={() => {
          setDepartmentModal(!departmentModal);
        }}
        departmentModalData={departmentModalData}
        editDepartment={editDepartment}
        addDepartment={addDepartment}
      />
      {/* Modal used to for additional data for an option or answer in case of input type question */}
      <LinkModal
        open={linkModal}
        toggle={() => {
          setLinkModal(!linkModal);
        }}
        linkModalData={linkModalData}
        questions={props.questions}
        setQuestions={props.setQuestions}
        pageType={props.pageType}
      />
      <TreeView
        dialogState={treeView}
        questions={props.questions}
        agents={props.agents}
        departments={props.departments}
        toggle={() => {
          setTreeView(!treeView);
        }}
      />
    </div>
  );
}

export default FlowBuilder;
