import React from "react";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";

import { AiOutlineCloseCircle } from "react-icons/ai";

const ChangeQuestion = (props) => {
  return (
    <div>
      <Modal isOpen={props.open.bool} className="modal-background">
        <ModalBody>
          <div className="p-2">
            <div className="mt-2 form-styles">
              <p style={{ fontSize: "20px" }} className="mb-2 mt-4">
                Are you sure you want to change the type of question?
              </p>
              <p style={{ fontSize: "14px", color: "red" }} className="m-0">
                * Changing question type leads to data loss.
              </p>
              <div
                style={{ gap: "15px" }}
                className="mt-4 d-flex justify-content-end"
              >
                <Button
                  onClick={() =>
                    props.toggle({ bool: !props.open.bool, value: null })
                  }
                  className="bg-danger text-white px-4"
                  color="none"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => props.changeQuestionType(props.open.value)}
                  className="bg-primary text-white px-4"
                  color="none"
                >
                  Change
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangeQuestion;
