import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { NotificationContainer } from "react-notifications";
import "./lib/reactifyCss";
import Layout from "components/layout";
import routerService from "services/_routeServices";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwtService from "services/jwtService";
import AdminLogin from "components/loginpage/AdminLogin";
import { useLocation } from "react-router-dom";
import NotFound from "components/pages/404";

function App(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const authCheck = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  //just for development
  // const authCheck = true;

  useEffect(() => {
    if (authCheck === false) {
      navigate("/admin/login");
    }
  }, [authCheck, navigate]);

  useEffect(() => {
    let interval = null;
    if (authCheck) {
      interval = setInterval(() => {
        // console.log("Refresh Set Interval");
        let user = JSON.parse(window.localStorage.getItem("user"));
        let refresh_token = window.localStorage.getItem("refresh_token");
        // console.log("user --->", user);
        // console.log("refresh_token --->", refresh_token);
        if (user && refresh_token) {
          jwtService.signInWithToken(user?.email, refresh_token);
        } else {
          clearInterval(interval);
        }
      }, 600000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [authCheck]);

  const getUrl = (pathname) => {
    return pathname === "/admin/login" ? true : false;
  };
  // const { location } = props;
  return (
    <div className="App">
      <NotificationContainer />
      {getUrl(location?.pathname) ? (
        <Routes>
          <Route exact path="/admin/login" element={<AdminLogin />} />
        </Routes>
      ) : (
        <Routes>
          <Route element={<Layout />}>
            {routerService &&
              routerService.map((route, key) => (
                <Route
                  exact
                  key={key}
                  path={`/admin/${route.path}`}
                  element={route.component}
                />
              ))}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
