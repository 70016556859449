import React from "react";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";

import { AiOutlineCloseCircle } from "react-icons/ai";

const HumanAgentModal = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    props.toggle();
    if (props.agentModalData) {
      props.editAgent(obj);
    } else {
      props.addAgent(obj);
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <AiOutlineCloseCircle
                onClick={() => props.toggle()}
                className="cursor-pointer"
                style={{ fontSize: "24px" }}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">
                {props.agentModalData ? "Edit" : "Add"} Human Agent
              </h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">Name</Label>
                  <Input
                    defaultValue={props.agentModalData?.data?.name}
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">Id</Label>
                  <Input
                    defaultValue={props.agentModalData?.data?.id}
                    type="text"
                    name="id"
                    required
                  />
                </div>

                <div className="mt-4 d-flex justify-content-end">
                  <Button className="bg-primary text-white px-4" color="none">
                    {props.agentModalData ? "Edit" : "Add"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HumanAgentModal;
