import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import { Link } from 'react-router-dom'

export default class NotFound extends Component {
  render () {
    return (
      <div className='' key='1'>
        <div className='error-body text-center mt-30'>
          <h2 className='oops'>Oops.. </h2>
          <h3 className='bold mb-0'>404</h3>
          <h2 className='error-msg mb-30'>Sorry, page not found</h2>
          <h4 className='mb-50'>
            {' '}
            Looks like you have navigated too far from Federation Space. Our
            Application <br />
            cannot help you here.
          </h4>
          <Button
            component={Link}
            to='/admin/flows'
            variant='contained'
            className='btn-light btn-lg'
          >
            Go To Chat flows
          </Button>
        </div>
      </div>
    )
  }
}
