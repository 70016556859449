import React from "react";
import { Input } from "reactstrap";

const InputType = ({
  question,
  questions,
  setQuestions,
  questionIndex,
  renderDynamicDropdown,
  pageType,
  setLinkModalData,
  setLinkModal,
}) => {
  return (
    <div>
      <div className="row mb-3  px-30  ">
        <div className="d-flex  col-12 justify-content-end">
          <Input
            style={{ maxWidth: 300, width: "100%" }}
            type="text"
            onKeyPress={(event) => {
              var regex = new RegExp("^[a-zA-Z_0-9]");
              var key = String.fromCharCode(
                !event.charCode ? event.which : event.charCode
              );
              if (!regex.test(key)) {
                event.preventDefault();
                return false;
              }
            }}
            className="w-100 border-0  bg-transparent d-inline-block  mr-4"
            placeholder={`${"Identifier .."} `}
            name="statement"
            required
            value={`${question.options[0]?.statement}`}
            onChange={(e) => {
              questions.forEach((mapQuestion) => {
                mapQuestion.options &&
                  mapQuestion.options.forEach((option) => {
                    if (option.externalApi) {
                      option.externalApi.params &&
                        option.externalApi.params.forEach((param) => {
                          if (param.value == question.options[0]?.statement) {
                            param.value = e.target.value;
                          }
                        });
                    }
                  });
              });
              let optionRef = question.options[0];
              optionRef.statement = e.target.value;
              setQuestions([...questions]);
            }}
            disabled={pageType === "View" ? true : false}
          />

          <Input
            style={{ maxWidth: 300, width: "100%" }}
            type="select"
            name="linkType"
            required
            className="mr-4 "
            value={`${question.options[0]?.linkType}`}
            onChange={(e) => {
              let optionRef = question.options[0];
              optionRef.linkType = e.target.value;
              optionRef.linkId = "";
              if (e.target.value !== "Link-To-Question") {
                optionRef.useExternalApi = false;
                if (optionRef.externalApi) {
                  delete optionRef.externalApi;
                }
              }
              setQuestions([...questions]);
            }}
            disabled={pageType === "View" ? true : false}
          >
            <option value="Link-To-Question">Link to question</option>
            <option value="End-Flow">End Flow</option>
          </Input>
          {renderDynamicDropdown(questionIndex, 0, "input")}
          <button
            type="button"
            className="btn text-primary px-4 bg-white border border-dark mr-4 align-item-start"
            onClick={() => {
              setLinkModal(true);
              setLinkModalData({
                item: questions[questionIndex].options[0],
                questionIndex: questionIndex,
                optionIndex: 0,
                type: "Input",
              });
            }}
          >
            Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputType;
